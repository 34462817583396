import React from "react";
import styled from "styled-components";
import { logo } from "../utils/images";
import { NavLink } from "react-router-dom";

const HeaderComponent = () => {
  return (
    <Header>
      <div className="header-container">
      <NavLink to="/" ><div className="logo">
          <img src={logo} alt="Logo" />
        </div></NavLink> 
        <nav className="nav-container">
          <NavLink to="/" className="link-nave">Home</NavLink>
          <NavLink to="/about" className="link-nave">About Us</NavLink>
          <NavLink to="getapp" className="link-nave">Get the App</NavLink>
        </nav>
      </div>
    </Header>
  );
};

export default HeaderComponent;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  background-color: #b83c36;
  .header-container {
    width: 82%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo img {
    width: 250px;
  }
  .nav-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    margin-right: 10px;
  }
  .link-nave {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }
`;
