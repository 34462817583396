import React from 'react'
import { imgabout } from '../utils/images';
import styled from 'styled-components';
import ImageSlider from '../components/ImageSlider';


const About = () => {
  return ( <AboutUsSection>
    <h1>About Us</h1>
    <div className="image-wrapper">
      <ImageSlider/>
    </div>
    <p className='jesus-heading' >Jesus Christ is Lord</p>
    <p>
      Welcome to Twi bible Asante Offline & complete Audio from Genesis to
      revelation.
      <br />
      You don’t have to strain your eyes and read the Bible because from
      Genesis to Revelation is being
      <br />
      read. This reading is not typical Twi reading. but modern reading
      <br />
      Brethren, this is the Twi Bible Asante you are looking for with great
      features
      <br />
      There is no other Twi Bible with complete audio Clean and perfect than
      this. Versions
      <br />
      The FASTEST Twi Bible app and most efficient way to read & study the
      Bible. Quickly navigate to any
      <br />
      verse and easily access tools to help further your understanding of the
      Bible.
    </p>
  </AboutUsSection>)
}

export default About;

const AboutUsSection = styled.section`
  width: 100%;
  background-color: #a10000;
  color: white;
  padding: 100px;
  h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .jesus-heading{
    width: 100%;
    border-bottom: 1px solid #e18d88;
    border-top: 1px solid #e18d88;
    font-weight: 600;
    line-height: 3.5em;
    letter-spacing: 2px;
    margin: 20px 0;
  }


  .image-wrapper {
    width: 100%;
    margin: 0 auto 2rem;
    overflow: hidden;
    border: 2px solid white;

    img {
      width: 100% !important;
      display: block;
      vertical-align: middle;
      border: 0;
    }
  }

  p {
    text-decoration: none;
    font-weight: 600;
    text-align: start;
    display: flex;
    justify-content: start;
    text-align: start;
  }
`;
