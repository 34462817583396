import React, { memo } from "react";
import Layout from "../layout/Layout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../app/pages/Home";
import About from "../app/pages/About";
import GetApp from "../app/pages/GetApp";
import Privacy from "../app/pages/Privacy";
import Terms from "../app/pages/Terms";
import Contact from "../app/pages/Contact";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route path={"/"} element={<Home />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/getapp"} element={<GetApp />} />
          <Route path={"/privacy"} element={<Privacy />} />
          <Route path={"/terms"} element={<Terms />} />
          <Route path={"/contact"} element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default memo(Routing);
