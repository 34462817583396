import React, { memo } from "react";
import styled from "styled-components";
import {
  Instagram,
  Facebook,
  twitter,
  linkin,
  tiktok,
  Youtube,
} from "../utils/images";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <FooterWrapper>
      <div className="footer-btn-container">
        <NavLink to="./privacy" className="footer-btn">
          Privacy
        </NavLink>
        <NavLink to="./terms" className="footer-btn">
          Terms
        </NavLink>
        <NavLink to="./contact" className="footer-btn">
        Contact Us
        </NavLink>
      </div>
      <p className="copy-text">&copy; 2024 ChristApp. All rights reserved.</p>
      <div className="social-icons">
        <a href="https://www.instagram.com/realchristapp/" target="_blank">
          <img src={Instagram} alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/realchristapp" target="_blank">
          <img src={Facebook} alt="Facebook" />
        </a>
        <a href="https://twitter.com/wix" target="_blank">
          <img src={twitter} alt="Twitter" />
        </a>

        <a
          href="https://il.linkedin.com/company/wix-com?trk=public_jobs_topcard_logo"
          target="_blank"
        >
          <img src={linkin} alt="LinkedIn" />
        </a>
        <a href="https://www.tiktok.com/@realchristapp" target="_blank"><img src={tiktok} alt="TikTok" /></a>
        <a href="https://www.youtube.com/@christappx" target="_blank"><img src={Youtube} alt="YouTube" /></a>
        
      </div>
    </FooterWrapper>
  );
}

export default memo(Footer);

const FooterWrapper = styled.div`
  background-color: #3a3a3a;
  color: white;
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .footer-btn-container {
    display: flex;
    gap: 10px;
  }

  .footer-btn {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    text-decoration: none;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  

    img {
      width: 35px;
      margin: 0 10px;
    }
  }
  .copy-text{
    font-family: 'Oswald', Arial, sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 15px;
  }
`;
