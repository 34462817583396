import React from "react";
import styled from "styled-components";
import { Appstore, globe, GooglePlay, payiocn } from "../utils/images";
import MissionSection from "../components/MissionSection";
import { useNavigate } from "react-router-dom";
import FeatureSection from "../components/Feature";

function Home() {
  const navigate = useNavigate();
  return (
    <>
      <HomeStyle>
        <div className="earth-imag-container">
          <img src={globe} className="earth-rotate-image" alt="" />
          <div className="read-me" onClick={() => navigate("/getapp")}>
            <p className="readme-para">Read More</p>
            <div class="up-arrow-box">
              <div class="up-arrow"></div>
            </div>
          </div>
        </div>
        <div className="paystorebtn-container">
          <div className="par-conatiner">
            <h1 className="twibible-head">My Bible</h1>
            <p>The Home of Global Bible Versions</p>
          </div>
          <div>
            <p onClick={() => navigate("/getapp")} className="download-btn">Download Bible Now</p>
            <div className="btn-app-box">
              <a
                href="https://apps.apple.com/us/app/my-bible-read-study-audio/id1449413749"
                target="_blank"
              >
           
                <img src={Appstore} alt="App Store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.paystub.mybible.app&hl=en_US&gl=us"
                target="_blank"
              >
                <img src={GooglePlay} alt="Google Play" />
              </a>
            </div>
          </div>
        </div>
      </HomeStyle>
      <MissionSection />
      <FeatureSection />
    </>
  );
}

export default Home;

const HomeStyle = styled.section`
  width: 100%;
  background-color: #a00301;
  color: white;
  display: flex;
  justify-content: space-around;
  min-height: 85vh;
  padding: 60px;
  padding-left: 100px;

  .earth-imag-container {
    width: 50%;
    display: flex;
    justify-items: center;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }
  .paystorebtn-container {
    width: 50%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    text-align: start;
    padding: 30px;
    gap: 50px;
  }
  .earth-rotate-image {
    width: 260px;
    height: 260px;
  }
  .up-arrow-box {
    width: fit-content;
    height: fit-content;
    border-radius: 100%;
    background: white;
    padding: 10px 4px 10px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    &:hover {
      background: #b8b5b5;
    }
  }
  .up-arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #a00301;
    rotate: calc(90deg);
  }
  .read-me {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  .readme-para {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
  }
  .twibible-head {
    color: white;
    font-weight: 700;
    font-family: initial;
    font-size: 42px;
  }

  .download-btn {
    color: #b8b8b8;
    font-family: emoji;
    line-height: 21px;
    cursor: pointer;
    font-size: 32px;
    margin-bottom: 10px;
    text-decoration: underline;
    transition: 0.3s;
    &:hover {
      color: #636161;
    }
  }
  .btn-app-box {
    display: flex;
    justify-content: start;
    gap: 20px;
    img {
      width: 150px;
      margin: 10px 0;
    }
  }
`;
