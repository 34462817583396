import React from "react";
import { Carousel } from "antd";
import { imgabout } from "../utils/images";

const ImageSlider = () => (
  <>
    <Carousel arrows infinite={false}>
      <div>
        <img src={imgabout} alt="About Us" />
      </div>
      <div>
        <img src={imgabout} alt="About Us" />
      </div>
      <div>
        <img src={imgabout} alt="About Us" />
      </div>
      <div>
        <img src={imgabout} alt="About Us" />
      </div>
    </Carousel>
  </>
);
export default ImageSlider;
