import React from "react";
import styled from "styled-components";

function MissionSection() {
  return (
    <MissionSectionContainer>
      <h2 className="our-mission">Our Mission</h2>
      <p className="our-para">
        Is to provide the Twi Bible in accurate, contemporary translations and
        formats
      </p>
      <p className="our-para">
        so that more people around the world will have the opportunity to be
      </p>
      <p className="our-para">
        so that more people around the world will have the opportunity to be
      </p>
      <p className="our-para">
        transformed by Jesus Christ. To those who cannot read Twi Bible has been
        read
      </p>
      <p className="our-para">
        from Genesis to Revelation. So all you need to do is just listen to the
        audio..
      </p>
      <p className="our-para">Including various Bible like</p>
      <p className="our-para">Twi Bible AKuapem</p>
      <p className="our-para">Swahili Bible</p>
      <p className="our-para">Ewe Bible</p>
      <p className="our-para">Zulu Bible</p>
      <p className="our-para">NKJV</p>
      <p className="our-para">ETC</p>
    </MissionSectionContainer>
  );
}

export default MissionSection;

const MissionSectionContainer = styled.section`
  background-color: #2d4715;
  padding: 50px 20px;
  color: white;
  text-align: center;

  .our-mission {
    font-size: 48px;
    font-family: "PT Sans Narrow", Arial, sans-serif;
    text-transform: none;
    font-weight: 400;
  }

  .our-para {
    font-family: Arial;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.8em;
    text-align: center;
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
  }
`;
