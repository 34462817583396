import React from "react";
import styled from "styled-components";
import getgrp from "../assets/getgrp.png";
import phone from "../assets/getapp.png";
import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img2.png";
import { Appstore, GooglePlay } from "../utils/images";

const GetApp = () => {
  return (
    <AppContainer>
      <div className="phone-container">
        <div>
          <h1>Install My Bible App Now</h1>
          <p>God's Word is with you everywhere you go!!</p>

          <div className="btn-app-box">
          <a
                href="https://apps.apple.com/us/app/my-bible-read-study-audio/id1449413749"
                target="_blank"
              >
              {" "}
              <img src={Appstore} alt="App Store" />
            </a>
            <a
                href="https://play.google.com/store/apps/details?id=com.paystub.mybible.app&hl=en_US&gl=us"
                target="_blank"
              >
              <img src={GooglePlay} alt="Google Play" />
            </a>
          </div>
        </div>
        <div>
          <img className="phone-image" src={phone} alt="Bible App Screenshot" />
        </div>
      </div>

      <section>
        <h2>Join over a million happy Bible readers and listeners!</h2>
        <div className="icons-container">
          <div className="icon-card">
            <img src={img1} alt="Our Story Icon" />
            <h3>Our Story</h3>
            <p>We take the Great Commission seriously.</p>
          </div>
          <div className="icon-card">
            <img src={img2} alt="Our Vision Icon" />
            <h3>Our Vision</h3>
            <p>
              Our vision is to see others enter into an authentic, life-changing
              relationship with Jesus.
            </p>
          </div>
          <div className="icon-card">
            <img src={img3} alt="The Lord's Command Icon" />
            <h3>The Lord's Command</h3>
            <p>Jesus commands us to go and make disciples from all nations.</p>
          </div>
        </div>
      </section>
      <div
        className="footer-section"
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "left",
          gap: "20px",
          // maxWidth: "800px",
          margin: "0 auto",
        }}
      >
        <img src={getgrp} alt="Last Supper Image" style={{ width: "60%" }} />
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <h2>Download the app now!</h2>
          <p>
            Jesus is the Way, the Truth, and the Life. You belong to God the
            Father and Jesus Christ the Lord. So, "Come to me, all you who are
            weary and burdened, and I will give you rest."
          </p>
        </div>
      </div>
    </AppContainer>
  );
};

export default GetApp;

const AppContainer = styled.div`
  color: white;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;

  .phone-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #8b0000;
    padding: 50px 80px;
  }

  h1 {
    font-size: 44px;
    color: #b8b8b8;
    font-family: serif;
    font-weight: 600;
  }

  p {
    font-size: 13px;
    color: white;
    line-height: 3em;
    font-family: "open sans", sans-serif;
    font-weight: 600;
  }

  .btn-app-box {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;

    img {
      width: 150px;
      cursor: pointer;
    }
  }

  .phone-image {
    width: 500px;
    height: 700px;
    margin-top: 20px;
  }

  section {
    background-color: #5c5c5c;
    padding: 20px;
    text-align: center;
    width: 100%;

    h2 {
      color: white;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .icons-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 50px 10px;

      .icon-card {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;

        img {
          width: 50px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }

        p {
          letter-spacing: 1px;
          font-size: 18px;
          font-weight: 300;
          color:white;
          line-height: 21px;
        }
      }
    }
  }

  .footer-section {
    background-color: black;
    color: white;
    padding: 30px 20px;
    text-align: center;
    width: 100%;

    h2 {
      font-size: 28px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;
    }
  }
`;
