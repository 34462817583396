import React from "react";
import styled from "styled-components";
import Bible from "../components/Bible";

const Privacy = () => {
  return (
    <PrivacyPolicyContainer>
      <div className="bile-container-terms">
        <Bible />
      </div>
      <div className="privcy-parrr">
      <h1>Privacy Policy and Notice of Information Practices</h1>
      <p>
        Privacy Policy and Notice of Information Practices Christapp, .
        (“Christapp,” “we,” “us” or “our”) respects the privacy of visitors to
        our websites, apps and online services and values the confidence of our
        customers, partners, and employees. This Privacy Policy and Notice of
        Information Practices (“Privacy Policy”) sets forth Christapp’s
        practices regarding the collection, use and disclosure of information
        that you may provide through the websites that we operate, their
        subdomains, and all portals (“Websites”), our mobile applications
        (“Apps”), all other products, services, events or interactive features
        (“Services”) that link to this Privacy Policy, as well as Personal
        Information provided to Christapp by any means. Additional privacy
        disclosures may be made at the time of collection of the information. WE
        DO NOT SELL YOUR PERSONAL INFORMATION. Please read the entire Privacy
        Policy before using our Websites, Apps or Services. By using the
        Websites, Apps or Services, you agree to abide by the terms of this
        Privacy Policy. This Privacy Policy is also part of our Terms of Use,
        which govern your use of the Websites and Apps.
      </p>
      <p>
        Collection of Information
        <br />
        Use of Information
        <br />
        Sharing of Information
        <br />
        Security
        <br />
        Response to “Do Not Track” Signals
        <br />
        Children’s Privacy
        <br />
        Your Choices Regarding Your Personal Data
        <br />
        International Transfers of Information
        <br />
        Your Additional Data Protection Rights Under the General Data Protection
        Regulation (GDPR)
        <br />
        Your Additional Data Protection Rights Under the Lei Geral de Proteção
        de Dados (LGPD)
        <br />
        Your California Privacy Rights
        <br />
        Links to Third-Party Content
        <br />
        Social Networking Services
        <br />
        Policy Updates
        <br />
        Contact Information
        <br />
        Collection of Information
      </p>
      <p>
        We may ask you for some or all of the following types of information
        when you access various content or features of the Websites, Apps or
        Services, submit content, or directly contact us: Contact information,
        such as name, email address, postal address, and telephone number;
        Online identifiers, such as Internet Protocol (IP) address, cookies,
        username, and password; Information from social media accounts;
        Annotations you make in the App or on the Websites (for your privacy, we
        discourage you from lauding sensitive health or other personal
        information in your annotations; any annotations made by you are at your
        own discretion and your own risk); Financial information, such as credit
        card, debit card or bank account number, if necessary for payment (we do
        not store this information); Search queries; Other Personal Information
        that may be contained in employment applications, luding submissions of
        your professional or educational background, your contact information
        and other information contained in resumes, cover letters,
        recommendations, and references; Personal Information contained within
        correspondence and other information that you send to us. We also may
        collect certain information automatically when you visit the Websites
        and Apps, luding: Your IP address, which is the number automatically
        assigned to your computer whenever you access the Internet and that can
        sometimes be used to derive your general geographic area; Other unique
        identifiers, luding mobile device identification numbers (e.g., IDFA,
        Android/Google Advertising ID, IMEI); Your browser type and operating
        system; Your device characteristics; Sites you visited before and after
        visiting the Websites and Apps; Pages you view and links you click on
        within the Websites and Apps, luding remembering you and your
        preferences; Your device location and/or other geolocation information,
        luding the zip code, state, or country from which you accessed the
        Websites and Apps; Information collected through cookies, web beacons
        and other technologies; Information about your interactions with email
        messages, such as the links clicked on and whether the messages were
        received, opened, or forwarded; Standard Server Log Information. We may
        use cookies, pixel tags and similar technologies to automatically
        collect this information. Cookies are small bits of information that are
        stored by your computer’s web browser. Pixel tags are very small images
        or small pieces of data embedded in images, also known as “web beacons”
        or “clear GIFs,” that can recognize cookies, the time and date a page is
        viewed, a description of the page where the pixel tag is placed, and
        similar information from your computer or device. By using the Websites
        or Apps, you consent to our use of cookies and similar technologies. You
        can decide if and how your computer will accept a cookie by configuring
        your preferences or options in your browser. However, if you choose to
        reject cookies, you may not be able to use certain online products,
        services or features on the Websites or Apps. You can get more
        information about Cookies and Tracking Technologies, and instructions on
        how to opt out of these items, in our Cookie Policy. Use of Information
        We may use information that we collect through the Websites, Apps or in
        person for a variety of purposes, luding to: Operate and improve our
        Websites, Apps and Services; Understand you and your preferences to
        enhance your experience and enjoyment using our Websites, Apps and
        Services; Process employment applications; Respond to your comments and
        questions and provide customer service; Maintain your account; Verify
        your identity and for fraud prevention; Provide and deliver products,
        information, and services you request and identify your preferences;
        Process your payments or purchases; Meet our audit, compliance, and
        regulatory obligations; Send you information, luding confirmations,
        invoices, technical notices, updates, security alerts and support and
        administrative messages; Communicate with you about upcoming events and
        news about products, information and services offered by Christapp or
        our selected partners; or combine with other Personal Information we get
        from third parties, to help understand your needs and provide you with
        better service; Assist when it is necessary for emergency medical
        purposes or to protect your or another person’s vital interests; Comply
        with a law, court order, or other judicial or administrative process;
        Protect, investigate, and deter against fraudulent, unauthorized, or
        illegal activity; and As otherwise described to you at the point of
        collection or with your consent. Sharing of Information We are committed
        to maintaining your trust, and we want you to understand when and with
        whom we may share the information we collect. We do not sell your
        Personal Information. We may need to provide your Personal Information
        to others in the following instances: Corporate Parents and Affiliates.
        As applicable, we may share your information with affiliated entities
        for a variety of purposes, luding business, operational and marketing
        purposes. Service Providers. We may share your information with service
        providers that perform certain functions or services on our behalf (such
        as to host the Websites and Apps, manage databases, process payments or
        other data, perform analyses or send communications for us). Other
        Parties When Required by Law or as Necessary to Protect the Websites and
        Apps. We may disclose your information to third parties in order to:
        protect the legal rights, safety and security of Christapp, affiliates
        and the users of our Websites and apps; enforce our Terms of Use;
        prevent fraud (or for risk management purposes); and comply with or
        respond to law enforcement or legal process or a request for cooperation
        by a government entity, whether or not legally required. In Connection
        with a Transfer of Assets. If we sell all or part of our business, or
        make a sale or transfer of assets, or are otherwise involved in a merger
        or business transfer, or in the event of bankruptcy, we may transfer
        your information to one or more third parties as part of that
        transaction. Other Parties with Your Express or Implied Consent. We may
        share information about you with third parties when you consent to such
        sharing. Aggregate Information. We may disclose to third parties
        information that does not describe or identify individual users, such as
        aggregate usage data or demographic reports for the Websites and Apps.
        (This is not considered Personal Information. In addition, we may allow
        third parties to place and read their own cookies, web beacons and
        similar technologies to collect information through the Websites and
        Apps. For example, our third-party service providers may use these
        technologies to collect information that helps us with traffic
        measurement, research, and analytics. Please note that you may need to
        take additional steps beyond changing your browser settings to refuse or
        disable some of these technologies. If you choose to refuse, disable, or
        delete these technologies, some of the functionality of the Websites and
        Apps may no longer be available to you. You understand that when you use
        the Websites and Apps, these analytics providers may collect information
        related to your use of the Websites and Apps. Security We maintain a
        variety of security procedures to help protect against loss, misuse,
        unauthorized access, disclosure, alteration, or destruction of the
        information you provide through the Websites and Apps. However, no data
        transmission over the Internet or stored on a server can be guaranteed
        to be 100% secure. As a result, while we strive to protect your
        information and privacy, we cannot guarantee or warrant the security of
        any information you disclose or transmit to us online and cannot be
        responsible for the theft, destruction or inadvertent disclosure of your
        information. Please see our Terms of Use for additional information.
        Response to “Do Not Track” Signals Do Not Track (“DNT”) is a web browser
        setting that requests a web application disable its tracking of an
        individual user. When you choose to turn on the DNT setting in your
        browser, your browser sends a special signal to Websites, analytics
        companies, ad networks, plug in providers, and other web services you
        encounter while browsing to stop tracking your activity. However,
        because there currently is no industry standard concerning what, if
        anything, Websites should do when they receive such signals, we
        currently do not take action in response to these signals. You can learn
        more about Do Not Track Children’s Privacy Christapp respects the
        privacy of children, and no part of our Site is targeted to attract
        anyone under the age of 13. Christapp does not knowingly collect, use,
        or disclose Personal Information from children under the age of 13
        without prior parental consent. Users from ages 13 to 15 must represent
        and warrant that they are visiting the Site under the supervision of a
        parent or guardian, and we may ask your parent or guardian to provide
        prior written consent for you to use the Site. By providing your
        consent, you agree that we may collect, use, and disclose your child’s
        Personal Information consistent with this Privacy Policy. If you believe
        we have information regarding a child under the age of 16 that you have
        not authorized, you may contact us at legal@Christapp.com. Your Choices
        Regarding Your Personal Data If you receive emails or other
        communications from us, you may indicate a preference to stop receiving
        further communications from us and you will have the opportunity to
        “opt-out” by following the unsubscribe instructions provided in the
        email you receive or by contacting us directly at our contact
        information below. If you opt out, we may still send you non-promotional
        emails, such as emails about our ongoing business relations. You may
        also request changes or updates to your Personal Information by sending
        a request at our contact information below. International Transfers of
        Information If you are visiting the Site from a location outside of the
        United States, your connection will be through and to servers located in
        the United States. All information you receive from the Site will be
        created on servers located in the United States, and all information you
        provide will be maintained on web servers and systems located within the
        United States. Your information, luding your Personal Information, may
        be transferred to – and maintained on --- computers located in the
        United States. The data protection laws in the United States may differ
        from those of the country in which you are located, and your information
        may be subject to access requests from governments, courts, or law
        enforcement in the United States according to laws of the United States.
        Your consent to this Privacy Policy, followed by your submission of your
        information represents your agreement to the collection, storage,
        processing and transfer of your information in and to the United States,
        or other countries and territories, pursuant to the laws of the United
        States. Christapp will take all the steps reasonably necessary to ensure
        that your Personal Information is treated securely and in accordance
        with this Privacy Policy and no transfer of your Personal Information
        will take place to an organization or a country unless there are
        adequate controls in place luding the security of your data and other
        Personal Information.Your Additional Data Protection Rights Under the
        General Data Protection Regulation (GDPR) If you are a resident of or
        located within the European Economic Area (EEA), you have certain
        additional data protection rights. These rights lude: The right to
        access, update or delete the information we have about you. Whenever
        made possible, you can access, update or request deletion of your
        Personal Information by contacting us at the contact information below.
        The right of rectification. You have the right to have your information
        rectified if that information is inaccurate or complete. The right to
        object. You have the right to object to our processing of your Personal
        Information. The right of restriction. You have the right to request
        that we restrict the processing of your Personal Information. The right
        to data portability. You have the right to be provided with a copy of
        the information we have on you in a structured, machine-readable, and
        commonly used format. The right to withdraw consent. You also have the
        right to withdraw your consent at any time where Christapp relied on
        your consent to process your Personal Information. Legal Bases for
        Processing Personal Information Under the GDPR Christapp is a controller
        of Personal Information. Christapp’s legal bases for collecting or
        processing your Personal Information are as follows: We need to provide
        a service to you; You have given us your consent to do so; The
        processing is in our legitimate interests and it is not overridden by
        your rights; or To comply with the law. Retention of Information
        Christapp will retain your Personal Information only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use your Personal Information to the extent necessary to
        comply with our legal obligations (for example, if we are required to
        retain your Information to comply with applicable laws), resolve
        disputes and enforce our legal agreements and policies. Christapp will
        also retain Personal Information and usage data for internal analysis
        purposes. Usage Data is data collected automatically either generated by
        the use of the Site or from the Site infrastructure itself (for example,
        the duration of a page visit). Usage Data is generally retained for a
        shorter period of time, except when this data is used to strengthen the
        security or to improve the functionality of our Site or we are legally
        obligated to retain this data for longer periods. Disclosure of Personal
        Information Christapp may disclose your Personal Information in the good
        faith belief that such action is necessary to: To comply with a legal
        obligation or in response to valid requests by public authorities or as
        otherwise set forth in this Privacy Policy; To protect and defend the
        rights or property of Christapp; To prevent or investigate possible
        wrongdoing in connection with the Service; To protect the personal
        safety of users of the Service or the public; and/or To protect against
        legal liability. Exercising Your Rights Under the GDPR If applicable,
        you may exercise any of your rights under the GDPR by submitting a
        verifiable data subject request to us by using the details in the
        Contact Information section below. You may make a request related to
        your Personal Information or on behalf of someone for which you have
        authorization. You must lude your full name, email address, and attest
        to the fact that you are a citizen or resident of the EEA by luding your
        country of citizenship or residence in your request. We may require you
        to confirm your identity and/or legal standing for the request as well
        as your residency in the EEA in order to obtain the information. We will
        respond to your request within 30 days or let you know if we need
        additional time. Please note that we will ask you to verify your
        identity before responding to such requests, and we may deny your
        request if we are unable to verify your identity or authority to make
        the request. Should you wish to raise a concern about our use of your
        information (and without prejudice to any other rights you may have),
        you have the right to do so with your local supervisory authority;
        however, we hope that we can assist with any queries or concerns you may
        have about our use of your Personal Information first by contacting us
        at legal@Christapp.com. For more information about the GDPR, please
        contact your local data protection authority in the EEA. Your Additional
        Data Protection Rights Under the Lei Geral de Proteção de Dados (LGPD)
        If you are located in the national territory of Brazil, you have certain
        additional data protection rights. These rights lude: The right to
        confirmation of the existence of the processing; The right to access the
        data; The right to correct complete, inaccurate or out-of-date data; The
        right to anonymize, block, or delete unnecessary or excessive data or
        data that is not being processed in compliance with the LGPD; The right
        to the portability of data to another service or product provider, by
        means of an express request; The right to delete personal data processed
        with the consent of the data subject; The right to information about
        public and private entities with which the controller has shared data;
        The right to information about the possibility of denying consent and
        the consequences of such denial; and The right to revoke consent. Legal
        Bases for Processing Data Under the LGPD Christapp’s legal bases for
        collecting or processing your Personal Information are as follows: We
        need to provide a service to you; You have given us your consent to do
        so; The processing is in our legitimate interests and it is not
        overridden by your rights; or To comply with the law. Duration of
        Processing Christapp will process and retain your Personal Information
        only for as long as is necessary for the purposes set out in this
        Privacy Policy. We will retain and use your Personal Information to the
        extent necessary to comply with our legal obligations (for example, if
        we are required to retain your Information to comply with applicable
        laws), resolve disputes and enforce our legal agreements and policies.
        Christapp will also retain Personal Information and usage data for
        internal analysis purposes. Usage Data is data collected automatically
        either generated by the use of the Site or from the Site infrastructure
        itself (for example, the duration of a page visit). Usage Data is
        generally retained for a shorter period of time, except when this data
        is used to strengthen the security or to improve the functionality of
        our Site or we are legally obligated to retain this data for longer
        periods. Exercising Your Rights Under the LGPD If applicable, you may
        exercise any of your rights under the LGPD by submitting a verifiable
        data subject request to us by using the details in the Contact
        Information section below. You may make a request related to your
        Personal Information or on behalf of someone for which you have
        authorization. You must lude your full name, email address, and attest
        to the fact that you are a citizen or resident of Brazil in your
        request. We may require you to confirm your identity and/or legal
        standing for the request as well as your residency in order to obtain
        the information. We will respond to your request within 15 days. Your
        California Privacy Rights As described above, Christapp collects certain
        types of Personal Information about you during your relationship with
        Christapp. Under the California Consumer Privacy Act of 2018 (CCPA), if
        you are a resident of California, you have the right to request certain
        information that we collect about you, luding: (1) The categories of
        Personal Information we have collected from you; (2) The categories of
        sources from which we collected the Personal Information; (3) The
        business purpose we have for collecting or selling that Personal
        Information; (4) The categories of third parties with whom we share such
        Personal Information; and (5) The specific pieces of Personal
        Information we have collected about you. In addition, if we sold or
        disclosed your Personal Information for a business purpose, you may
        request that we provide you with: (1) The categories of Personal
        Information that we sold about you, and the categories of third parties
        to whom the Personal Information was sold, by category or categories of
        Personal Information for each category of third parties to whom the
        information was sold; or if we have not sold consumers’ Personal
        Information; and (2) The categories of Personal Information that we have
        disclosed about you for a business purpose, and the categories of third
        parties to whom the Personal Information was disclosed, by category or
        categories of Personal Information for each category of third parties to
        whom the information was disclosed; or if we have not disclosed
        consumers’ Personal Information for a business purpose. As a California
        resident, you also have the right, at any time, to tell us not to sell
        Personal Information – this is called the “right to opt-out” of the sale
        of Personal Information. We do not currently sell your Personal
        Information. You also have the right to request that we delete any of
        your Personal Information that we collected from you and retained,
        subject to certain exceptions. Once we receive and confirm your
        verifiable consumer request, we will delete (and direct our service
        providers to delete) your Personal Information from our records, unless
        an exception applies. We may deny your deletion request if retaining the
        information is necessary for us or our service providers to: Complete
        the transaction for which we collected the Personal Information, provide
        a good or service that you requested, take actions reasonably
        anticipated within the context of our ongoing business relationship with
        you, or otherwise perform our contract with you; Detect security idents,
        protect against malicious, deceptive, fraudulent, or illegal activity,
        or prosecute those responsible for such activities; Debug products to
        identify and repair errors that impair existing intended functionality;
        Exercise free speech, ensure the right of another consumer to exercise
        their free speech rights, or exercise another right provided for by law;
        Comply with the California Electronic Communications Privacy Act (Cal.
        Penal Code § 1546 seq.); Engage in public or peer-reviewed scientific,
        historical, or statistical research in the public interest that adheres
        to all other applicable ethics and privacy laws, when the information's
        deletion may likely render impossible or seriously impair the research's
        achievement, if you previously provided informed consent; Enable solely
        internal uses that are reasonably aligned with consumer expectations
        based on your relationship with us; Comply with a legal obligation; or
        Make other internal and lawful uses of that information that are
        compatible with the context in which you provided it. Please note that
        if we collected information about you for a single one-time transaction
        and do not keep that information in the ordinary course of business,
        that information will not be retained for purposes of a request under
        this section. In addition, if we have de-identified or anonymized data
        about you, we are not required to re-identify or otherwise link your
        identity to that data if it is not otherwise maintained that way in our
        records. Exercising Your Rights Under the CCP If applicable, you may
        exercise any of your rights under California law by submitting a
        verifiable data subject request to us by using the details in the
        Contact Information section below. Only you or your authorized agent may
        make a verifiable consumer request related to your Personal Information.
        You may also make a verifiable consumer request on behalf of your minor
        child. You must lude your full name, email address, and attest to the
        fact that you are a California resident by luding a California postal
        address in your request. We may require you to confirm your identity
        and/or legal standing for the request as well as your residency in
        California in order to obtain the information, and you are only entitled
        to make this request twice a year. We will respond to your request
        within 45 days or let you know if we need additional time. We may be
        unable to respond to your request or provide you with Personal
        Information if we cannot verify your identity or authority to make the
        request and confirm the Personal Information relates to you. Making a
        verifiable consumer request does not require you to create an account
        with us. We will only use Personal Information provided in a verifiable
        consumer request to verify the requestor’s identity or authority to make
        the request. Links to Third-Party Content As a convenience to our
        visitors, the Websites and Apps may link to a number of sites, services
        and other content that are operated and maintained by third parties.
        These third parties operate independently from us, and we do not control
        their privacy practices. You are encouraged to use common sense when
        sharing your Personal Information, and you should be aware when you
        leave our Site to visit another Site. None of the links on this Site
        should be deemed to imply that Christapp endorses the content or has any
        affiliation with the persons or entities associated therewith. This
        Privacy Policy does not apply to third-party content. We encourage you
        to review the privacy policies of any third party to whom you provide
        information. Social Networking Services Christapp may work with certain
        third-party social media providers to offer you their social networking
        services through our Websites and Apps. For example, you can use
        third-party social networking services such as Facebook to log into your
        Christapp account. These social networking services may be able to
        collect information about you, luding your activity on our Websites and
        Apps. These third-party social networking services also may notify your
        friends, both on our Websites and Apps and on the social networking
        services themselves, that you are a user of our Websites and Apps or
        about your use of our Websites and Apps, in accordance with applicable
        law and their own privacy policies. If you choose to access or make use
        of third-party social networking services, we may receive information
        about you that you have made available to those social networking
        services, luding information about your contacts on those social
        networking services. Policy Updates This Privacy Policy may be revised
        from time to time as we add new features and services, as laws change,
        and as industry privacy and security best practices evolve. We display
        an effective date on the policy in the upper left corner of this Privacy
        Policy so that it will be easier for you to know when there has been a
        change. If we make any material change to this Privacy Policy regarding
        use or disclosure of Personal Information, we will provide advance
        notice through the Websites and Apps. Small changes or changes that do
        not significantly affect individual privacy interests may be made at any
        time and without prior notice. If you are concerned about how your
        Personal Information is used, please visit our Site often for this and
        other important announcements and updates.
      </p>
      </div>
    </PrivacyPolicyContainer>
  );
};

export default Privacy;

const PrivacyPolicyContainer = styled.div`
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
  background: #636363;
  padding: 60px;
  .bile-container-terms {
    margin: 20px 0;
    background-color: black;
  }
  .privcy-parrr{
    margin: 100px 20px;
    background-color: black;
    color:#fff;
    padding: 50px 100px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 40px;

  }

  p {
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 16px;
  }

  strong {
    font-weight: bold;
  }

  ul {
    margin-bottom: 20px;
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;
