import React, { memo } from "react";
import styled from "styled-components";
import HeaderComponent from "../app/components/Header";
import Footer from "../app/components/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <LayoutStyle>
      <div className="layout-header">
        <HeaderComponent />
      </div>
      <div className="layout-outlate">
        <Outlet />
      </div>
      <div className="layout-footer">
        <Footer />
      </div>
    </LayoutStyle>
  );
};

export default memo(Layout);

const LayoutStyle = styled.div`
  width: 100%;

  .layout-header {
    width: 100%;
  }
  .layout-outlate {
    width: 100%;
  }
`;
