import React from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ContactPage = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission
    console.log("Form values:", values);
    resetForm();
  };

  return (
    <ContactContainer>
      <h1>Contact Us</h1>
      <p>
        If you have any queries, concerns, or wish to reach out to us about My
        Bible, please don't hesitate to contact us. We will get back to you
        within 24 hours.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Field type="text" name="firstName" placeholder="First Name" />
            <p className="errer-para">
              <ErrorMessage
                name="firstName"
                component="div"
                className="error"
              />
            </p>
            <Field type="text" name="lastName" placeholder="Last Name" />
            <p className="errer-para">
              <ErrorMessage name="lastName" component="div" className="error" />
            </p>
            <Field type="email" name="email" placeholder="Your email" />
            <p className="errer-para">
              <ErrorMessage name="email" component="div" className="error" />
            </p>{" "}
            <Field type="text" name="subject" placeholder="Subject" />
            <p className="errer-para">
              <ErrorMessage name="subject" component="div" className="error" />
            </p>
            <Field
              as="textarea"
              name="message"
              rows="4"
              placeholder="Your message"
            />
            <p className="errer-para">
              <ErrorMessage name="message" component="div" className="error" />
            </p>
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </ContactContainer>
  );
};

export default ContactPage;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding: 2rem;

  h1 {
    color: #000;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  p {
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    width: 55%;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    input,
    textarea {
      padding: 0.8rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        border-color: #66afe9;
        outline: none;
      }
    }

    button {
      padding: 0.8rem 2rem;
      font-size: 1rem;
      color: #fff;
      background-color: #b83c36;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        background-color: #d1342b;
      }
    }
    .errer-para {
      height: 20px;
      margin: 0;
    }
    .error {
      color: #d1342b;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
