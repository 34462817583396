import React from 'react';
import styled from 'styled-components';
import { cross } from '../utils/images';


const Bible = () => {
  return (
    <AppContainer>
      <div className="image-container">
        <img className="image" src={cross} alt="Cross" />
      </div>
      <div className="text-container">
        <h1 className="title">Twi Bible Asante</h1>
        <p className="paragraph">
          Welcome to Akan Twi Bible Asante, Ewe bible, Akuapem and Complete Drama Audio for Android.
        </p>
        <p className="paragraph">
          Brethren, this is the Twi Bible you are looking for with great features. There is no other Twi Bible Clean and perfect than this. Check out our other Twi Bible Versions.
        </p>
        <p className="paragraph">
          This Twi Bible is the 2012 Edition with New Testament Audio Drama Asante.
        </p>
      </div>
    </AppContainer>
  );
};

export default Bible;

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding:40px;

  .image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image {
      height: 50%;
    width: 50%;
    border-radius: 10px;
  }

  .text-container {
    flex: 1;
    color: white;
    padding: 20px;
    text-align: left;
  }

  .title {
    font-size: 3em;
    margin-bottom: 20px;
  }

  .paragraph {
    font-size: 1.2em;
    line-height: 1.5;
  }
`;
