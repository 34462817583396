import React from "react";
import styled from "styled-components";
import { side } from "../utils/images";
import Bible from "../components/Bible";

const Terms = () => {
  return (
   
    
      <StyledComponents>
        <div className="bile-container-terms">
        <Bible />
        </div>
        <footer>
            <div className="footer-links">
              <h1 className="my-story">My Story</h1>
              <p className="tems-parar">
                Thank you for choosing Christapp, Inc. (“Christapp” “we” or
                “us”). We provide services on the Internet through our website
                located at www.Christappbible.com (including the mobile versions
                thereof, “Website”) and mobile Bible software applications for
                iOS and Android (“Mobile Apps”). The following terms and
                conditions (together with any other documents referred to in
                them) (“Terms of Use”) apply to your use of the Website and
                Mobile Apps, including any related product, mobile software
                application, service, content or functionality offered on or
                through the Website or Mobile Apps (individually “Christapp
                Service” and collectively, “Christapp Services”). Please read
                these Terms of Use carefully before you start to use any
                Christapp Service. By using or accessing any Christapp Service,
                you accept and agree to abide by these Terms of Use and our
                Privacy Policy found at www.twibible.org and incorporated herein
                by this reference. You agree that Christapp will process your
                personal data in accordance with the Privacy Policy. If you do
                not want to agree to these Terms of Use or the Privacy Policy,
                you must cease using all Christapp Services. Violation of the
                Terms of Use should be reported via email to
                legal@Christapp.com. We may revise and update these Terms of Use
                and/or Privacy Policy from time to time in our sole discretion
                and such revisions and updates will be effective immediately
                upon being posted on the Christapp Services. We will use good
                faith efforts to notify you of any revisions and updates to
                these Terms of Use. Your continued use of any Christapp Service
                following the posting of revised Terms of Use means that you
                accept and agree to the changes. Description of Services
                Christapp offers a variety of products and services. We develop
                mobile Bible software for users to read, study and share the
                Bible - products include the Christapp Bible, Zondervan NIV
                Study Bible, NIV Bible, The Holy Bible - King James Version
                (free), The One-Year Men Devo and MacArthur Daily Bible.
                Features available to some or all Christapp Service users may be
                different than features offered to other Christapp Service
                users, for example, although our Website provides users a web
                experience similar to services, features, and content similar to
                our Mobile Apps, in some cases, our Website will have services,
                features and content not available on our Mobile Apps. All of
                our products and services are covered by our Privacy Policy,
                which describes how we collect, use and disclose your
                information. By using any of Christapp Services, you are
                accepting and agreeing to the Terms of Use and Privacy Policy.
                Registration and Account Security You are not required to
                register or create an account in order to use the Website or
                Mobile Apps. If you do not register or create an account,
                certain features and functionality will not be available to you
                when using Christapp Services. By using Christapp Services, you
                are agreeing to make the following commitments relating to
                registration and security: You agree to provide information
                about yourself that is true, accurate, current and complete when
                registering for Christapp Services. You will not provide any
                false information or create an additional account for anyone
                other than yourself without permission. Access to Christapp
                Services is permitted on a temporary basis, and we reserve the
                right to withdraw, suspend or amend any service we provide on
                Christapp Services in our sole discretion without notice. We
                will not be liable if for any reason all or any part of
                Christapp Services is unavailable at any time or for any period.
                From time to time, we may restrict, suspend or terminate access
                to some parts of Christapp Services or all of Christapp
                Services, to users, including registered users. Upon any such
                restriction, suspension or termination, your right to use the
                Christapp Services will immediately be restricted, suspended or
                terminated. You agree that we shall not be liable to you or any
                third party for any restriction, suspension or termination of
                your access to the Christapp Services. Upon termination, all
                provisions of these Terms of Use which are by their nature
                intended to survive termination, all representations and
                warranties, all limitations of liability, and all indemnities
                shall survive such termination. You are responsible for making
                all arrangements necessary for you to access and to use
                Christapp Services, including any necessary fees for internet
                access and the provision of your own computing devices. You will
                keep your information up-to-date and accurate. You will not
                share your password that may compromise the security of your
                account. You are responsible for maintaining the confidentiality
                and safety of your username and password. You are responsible
                for all activities associated with your username and password.
                You will not transfer your account to anyone. You must
                immediately notify Christapp of any unauthorized use of your
                account. We have the right to disable any user identification
                code or password, whether chosen by you or provided by us, at
                any time, for any reason, including, if in our opinion, you have
                failed to comply with any provision of the Terms of Use. FOR
                PARENTS OF CHILDREN UNDER 13 WHO ALLOW THEIR CHILDREN TO USE
                CHRISTAPP SERVICES, IT IS YOUR RESPONSIBILITY TO DETERMINE IF
                CHRISTAPP SERVICES ARE APPROPRIATE FOR YOUR CHILD. IF YOU ARE
                UNDER THE AGE OF 13, THEN YOU ARE NOT PERMITTED TO REGISTER AS A
                USER OR OTHERWISE SUBMIT PERSONAL INFORMATION TO US. THE
                CHRISTAPP SERVICES ARE NOT DIRECTED TO CHILDREN UNDER THE AGE OF
                13. Purchases of Products and Services You may be required to
                provide personal information such as credit card information and
                billing information to an independent third party (“Processor”)
                that we choose in order to facilitate transactions on the
                Christapp Service. We may have multiple Processors for various
                services and products. The Processor is responsible for
                collecting, transmitting and processing your financial
                information. In some instances, we may choose a Processor to not
                only collect your financial information and process your order,
                but also to fulfill your order. All payment obligations will be
                governed by the terms of use and privacy policies of the
                Processor. You are responsible for any data breach of your
                personal financial information or any problems related to the
                processing of your transaction. We make no warranties and accept
                no liability for any loss or damages in connection with the
                Processor(s). Christapp does not request or store any credit
                card or debit card information nor any billing information.
                Refund Policy Thank you for using Christapp Bible. If you are
                not satisfied with the purchase you made from the Website or
                made a purchasing error on the Website, you may request a refund
                within 10 days of the purchase. It is important to note that we
                are not responsible for purchases made via the Mobile Apps from
                the Apple App Store, Google Play, Amazon or any other similar
                app store. We will not be able to provide refunds on purchases
                made on those app stores and will be subject to its specific
                terms of use and refund policy. In order to receive your refund
                for a purchase from the Website, please send the following
                information to biblesupport@Christapp.com. It is your
                responsibility to promptly notify your bank of any changes to
                your billing information or the loss, theft, or unauthorized use
                of your credit card or debit card number. Name Christapp Bible
                sync account email (used to purchase product) Date of purchase
                Product title Reason for refund Copy of receipt Privacy Your
                privacy is very important to us. We collect and use information
                about you in accordance with our Privacy Policy. By using
                Christapp Services, you consent to such collection and you
                represent and warrant that all data provided by you is accurate.
                We encourage you to read the Privacy Policy found at
                Christappbible.com/privacy. Intellectual Property Rights The
                entire contents of Christapp Services (including all
                information, software, text, displays, images, video and audio
                and any derivatives thereof) and the design, selection and
                arrangement thereof, are owned by Christapp or its licensors and
                are protected by United States and international laws regarding
                copyrights, trademarks, trade secrets and other intellectual
                property or proprietary rights. No right, title or interest in
                or to Christapp Services or any related content are transferred
                to you, and all rights not expressly granted are reserved by the
                Company. Any use of the Christapp Services not expressly
                permitted by these Terms of Use is a breach of these Terms of
                Use and may violate copyright, trademark and other laws. You
                will not modify, create derivative works, attempt to decompile,
                or otherwise extract source code from the Christapp Services.
                You are permitted to use the Christapp Services for personal,
                non-commercial use only. You must not copy, modify, create
                derivative works of, publicly display or perform, republish,
                download or store, or transmit any of the material on our
                Website or Mobile Apps without the prior written consent of the
                Company, except to: Store copies of such materials temporarily
                in RAM. Store files that are automatically cached by your Web
                browser for display enhancement purposes. Print a reasonable
                number of pages of the Website for a permitted use under
                applicable law. You must not: Modify the paper or digital copies
                of any materials from the Website or Mobile Apps. Use any
                illustrations, photographs, video or audio sequences or any
                graphics separately from the accompanying text in the Bible
                version or materials provided to you via the Christapp Services.
                Delete or alter any copyright, trademark and other proprietary
                notices appearing on such materials. Except as expressly
                permitted in these Terms of Use, you must not reproduce, sell or
                exploit for any commercial purposes any part of the Christapp
                Services, access the Christapp Services or use of the Christapp
                Services (or any materials available in any of the foregoing)
                without obtaining a license to do so from the Company or its
                applicable licensor. If you wish to make any use of material on
                the Website or Mobile Apps other than that set out above, please
                address your request to: legal@Christapp.com. If you print,
                copy, modify, download or otherwise use any part of Christapp
                Services in breach of the Terms of Use, your right to use
                Christapp Services will cease immediately and you must, at our
                option, return or destroy any copies of the materials you have
                made. Christapp Trademarks Christapp’s name, Christapp’s logo
                and all related names, images, product and service names,
                designs and slogans are the intellectual property of Christapp
                or its affiliates or licensors. In addition, the look and feel
                of the Christapp Services constitute the service mark, trademark
                and/or trade dress of Christapp and may not be copied, imitated
                or used, in whole or in part, without the express prior written
                permission of Christapp. You must not use such intellectual
                property without the prior written permission of Christapp. All
                other names, brands and marks are used for identification
                purposes only and are the trademarks of their respective owners.
                Our trademarks and trade dress may not be used in any manner
                that is likely to cause confusion among customers, or in any
                manner that disparages Christapp or suggests a sponsorship,
                affiliation or endorsement by Christapp. Prohibited Uses You may
                use Christapp Services only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use
                Christapp Services: In any way that violates any applicable
                federal, state, local and international law or regulation
                (including, without limitation, any laws regarding the export of
                data or software to and from the United States or other
                countries). For the purpose of exploiting, harming or attempting
                to exploit or harm minors in any way by exposing them to
                inappropriate content, asking for personally identifiable
                information or otherwise. To send, knowingly receive, upload,
                download, use or reuse any material which does not comply with
                the Content Standards set forth below out in these Terms of Use.
                To transmit, or procure the sending of, any advertising or
                promotional material, including any "junk mail", "chain letter"
                or "spam" or any other similar solicitation. To impersonate or
                attempt to impersonate Christapp or a Christapp employee,
                another user, or person or entity (including, without
                limitation, the use of e-mail addresses associated with any of
                the foregoing). To engage in any other conduct that restricts or
                inhibits anyone's use or enjoyment of Christapp Services, or
                which, as determined by us, may harm the Company or users of
                Christapp Services or expose them to liability. Additionally,
                you agree not to: Use any Christapp Service in any manner that
                could disable, overburden, damage, or impair Christapp Service
                or interfere with any other party's use of any Christapp
                Service, including their ability to engage in real time
                activities through such Christapp Service. Use any robot, spider
                or other automatic device, process or means to access any
                Christapp Service. Use any manual process to monitor or copy any
                of the material on any Christapp Service or for any other
                unauthorized purpose without our prior written consent. Use any
                device, software or routine that interferes with the proper
                working of a Christapp Service or introduce any viruses, trojan
                horses, worms, logic bombs or other material which is malicious
                or technologically harmful. Attempt to gain unauthorized access
                to, interfere with, damage or disrupt any parts of any Christapp
                Service, the servers on which any Christapp Service is stored or
                hosted, or any server, computer or database connected to any
                Christapp Service. Attack any Christapp Service using a
                denial-of-service attack or a distributed denial-of-service
                attack. Otherwise attempt to interfere with the proper working
                of any Christapp Service. User Contributions Christapp Services
                may allow you to post, submit, publish, display or transmit to
                other users and the public content or materials (collectively,
                “User Contributions”) on or through Christapp Services. All User
                Contributions must comply with the Content Standards set out
                below in these Terms of Use. Any User Contribution you post to
                the site will be considered non-confidential and
                non-proprietary, and we have the irrevocable, royalty-free,
                perpetual right and license to use, copy, distribute, exploit
                and disclose to third parties any such material for any purpose.
                You further irrevocably waive any “moral rights” or other rights
                with respect to attribution of authorship or integrity of
                materials regarding User Contributions that you may have under
                any applicable law under any legal theory. To the extent not
                waivable, you irrevocably agree not to exercise such rights (if
                any) in a manner that interferes with any exercise of the
                granted rights. You represent and warrant that you own or
                control all rights in and to the User Contributions and have the
                right to grant Christapp and its affiliates the license granted
                above. You represent and warrant that all of your User
                Contributions do and will comply with these Terms of Use, and
                you agree to defend, indemnify and hold harmless Christapp and
                its affiliates and licensors for any breach of that
                representation and warranty. You understand and acknowledge that
                you are responsible for any User Contributions you submit or
                contribute, and you, not Christapp, have full responsibility for
                such content, including its legality, reliability, accuracy and
                appropriateness. Any content and/or opinions uploaded, expressed
                or submitted to Christapp Services, and all articles and
                responses to questions and other content, other than the content
                provided by Christapp, are solely the opinions and the
                responsibility of the person or entity submitting them and do
                not necessarily reflect the opinion of Christapp. We are not
                responsible or liable to any third party for the content or
                accuracy of any materials posted by you or any other user of
                Christapp Services. We cannot reasonably guarantee that all
                content published by users will be accurate and suitable. By
                using Christapp Services, you understand and agree that you may
                be exposed to content that is abusive, inflammatory, hateful,
                objectionable, offensive, questionable and indecent. Monitoring
                and Enforcement: Termination We may or may not monitor, review,
                or edit User Contribution. However, we have the right to the
                following: Remove or refuse to post any User Contributions for
                any reason in our sole discretion. Take any action with respect
                to any User Contribution that we deem necessary or appropriate
                in our sole discretion if we believe that such User Contribution
                violates the Terms of Use, including the Content Standards,
                infringes any intellectual property right, threatens the
                personal safety of users of the Website and the public or could
                create liability for Christapp or its affiliates. Disclose your
                identity to any third party who claims that material posted by
                you violates their rights, including their intellectual property
                rights or their right to privacy. Take appropriate legal action,
                including without limitation, referral to law enforcement, for
                any illegal or unauthorized use of any Christapp Service.
                Terminate your access to all or part of any Christapp Service
                for any or no reason, including without limitation, any
                violation of these Terms of Use. Without limiting the foregoing,
                we have the right to fully cooperate with any law enforcement
                authorities or court order requesting or directing us to
                disclose the identity of anyone posting any materials on or
                through a Christapp Service. YOU WAIVE AND HOLD HARMLESS
                CHRISTAPP FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
                CHRISTAPP DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM
                ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER
                CHRISTAPP OR LAW ENFORCEMENT AUTHORITIES. However, we can
                neither review all material before it is posted on a Christapp
                Service nor ensure prompt removal of objectionable material
                after it has been posted. Accordingly, we assume no liability
                for any action or inaction regarding transmissions,
                communications or content provided by any user or third parties.
                We have no liability or responsibility to anyone for performance
                or nonperformance of the activities described in this paragraph.
                In your communications with us, please keep in mind that we do
                not seek any unsolicited ideas or materials for products or
                services, or even suggested improvements to products or
                services, including, without limitation, ideas, concepts,
                inventions, or designs for websites, or otherwise (collectively,
                “Unsolicited Ideas and Materials”). Any Unsolicited Ideas and
                Materials you submit are deemed User Contributions and licensed
                to us as set forth above. You are solely responsible for your
                interaction with other users of Christapp Services, whether
                online or offline. We reserve the right, but have no obligation,
                to monitor or become involved in disputes between you and other
                users. Content Standards These content standards apply to any
                and all User Contributions. User Contributions must in their
                entirety comply with all applicable federal, state, local and
                international laws and regulations. Without limiting the
                foregoing, User Contributions must not: Contain any material
                which is defamatory, obscene, indecent, abusive, offensive,
                harassing, violent, hateful, inflammatory or otherwise
                objectionable. Promote sexually explicit or pornographic
                material, violence, or discrimination based on race, sex,
                religion, nationality, disability, sexual orientation or age.
                Infringe any patent, trademark, trade secret, copyright or other
                intellectual property rights of any other person. Violate the
                legal rights (including the rights of publicity and privacy) of
                others or contain any material that could give rise to any civil
                or criminal liability under applicable laws or regulations or
                that otherwise may be in conflict with these Terms of Use and
                our Privacy Policy. Be likely to deceive any person. Promote any
                illegal activity, or advocate, promote or assist any unlawful
                act. Cause annoyance, inconvenience or needless anxiety or be
                likely to upset, embarrass, alarm or annoy any other person. Be
                used to impersonate any person, or to misrepresent your identity
                or affiliation with any person or organization. Involve
                commercial activities and/or sales without our prior written
                consent, such as contests, sweepstakes and other sales
                promotions, barter, advertising or pyramid schemes. Give the
                impression that they emanate from us, if this is not the case.
                Copyright Infringement If you believe that any User
                Contributions violate your copyright, please see below for
                instructions on sending a notice of copyright infringement to
                Christapp’s copyright agent as set forth in the Digital
                Millennium Copyright Act of 1998 (“DMCA”). In order for your
                complaint to be valid under the DMCA, you must provide the
                following information in writing: A written description of the
                copyrighted work that you claim has been infringed; A written
                description of the location of the copyrighted work that is
                claimed to be infringed; A written statement that you have a
                good faith belief that the use is not authorized by the owner of
                the copyright; Contact information including address, telephone
                number and email address; A written statement that your claim is
                true to the best of your knowledge; An electronic or physical
                signature. The foregoing information must be submitted to the
                following DMCA Agent: Christapp, Inc. 2479 E. Bayshore Road
                Suite 185 Palo Alto, California 94303 Attn: DMCA Notice
                (legal@Christapp.com) Under United States federal law, if you
                knowingly misrepresent that online material is infringing, you
                may be subject to criminal prosecution for perjury and civil
                penalties, including monetary damages, court costs and
                attorneys’ fees. Links from the Christapp Services If Christapp
                Services contains links to other sites and resources provided by
                third parties, these links are provided for your convenience
                only. This includes links from advertisers, including banner
                advertisements and other advertising media. We have no control
                over the contents of those sites or resources, and accept no
                responsibility for them or for any loss or damage that may arise
                from your use of them. If you decide to access any of the third
                party websites or services linked to Christapp Services, you do
                so entirely at your own risk and subject to the terms and
                conditions of use for such websites or services. Devices and
                Connectivity You are responsible for obtaining and maintaining
                all devices and other equipment and software, and all internet
                service providers, mobile services, and other services needed
                for your access to and use of the Christapp Services and you
                will be responsible for all charges related to them. You further
                agree to look solely to the entity that manufactured and/or sold
                you the device for any issues related to the device and its
                compatibility with the Christapp Services. By using Christapp
                Services, you agree that Christapp may change, alter, or modify
                the settings or configurations on your device in order to allow
                for or optimize your use of the Christapp Services. For
                instance, a Christapp Service may access and read accounts, data
                and/or content on your device, add metadata to your device, and
                change settings of your device, for reasons such as showing you
                the location of things near you; saving images, sound files and
                writing usage logs to the device; sending Facebook and Twitter
                messages you initiate; sending and receiving data needed for
                Christapp Service operations; and to provide you notice when you
                are not connected to a network, but in each case subject to the
                Privacy Policy. You consent to these activities by installing or
                otherwise using a Christapp Service and by agreeing to these
                Terms of Use and the Privacy Policy. Your device settings may
                enable you to disable, change or limit some of these activities,
                and you can disable all of them associated with a Christapp
                Service by uninstalling a Christapp Service. When using the
                Christapp Services, you agree to comply with any and all
                third-party terms that are applicable to any platform, website,
                technology or service that interacts with Christapp Service.
                Geographic Restrictions We are based in the State of California
                in the United States. We make no claims that Christapp Services
                or any of its content is accessible or appropriate outside of
                the United States. Access to Christapp Services may not be legal
                by certain persons or in certain countries. If you access
                Christapp Services from outside the United States, you do so on
                your own initiative and are responsible for compliance with
                local laws. DISCLAIMER OF WARRANTIES You understand that we
                cannot and do not guarantee or warrant that any of the Christapp
                Services (or content therein) will be free of viruses, safe,
                secure, error-free or that any Christapp Service will always
                function without disruption, delays or imperfections. You are
                responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to Christapp Services, if
                applicable, for any reconstruction of any lost data. WE WILL NOT
                BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
                DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY
                HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
                COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO
                YOUR USE OF ANY CHRISTAPP SERVICE (OR ITEMS OBTAINED THROUGH ANY
                SUCH CHRISTAPP SERVICE OR TO YOUR USE OF ANY MATERIAL POSTED ON
                IT, OR ON ANY WEBSITE OR SERVICES LINKED TO IT). YOUR USE OF
                CHRISTAPP SERVICES, ITS CONTENT AND ANY SERVICES OR SOFTWARE
                OBTAINED THROUGH A CHRISTAPP SERVICE OR APP STORES IS AT YOUR
                OWN RISK. CHRISTAPP SERVICES, ITS CONTENT AND ANY SERVICES OR
                ITEMS OBTAINED THROUGH CHRISTAPP SERVICES ARE PROVIDED ON AN "AS
                IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY
                KIND, EITHER EXPRESS OR IMPLIED. NEITHER CHRISTAPP NOR ANY
                PERSON ASSOCIATED WITH CHRISTAPP MAKES ANY WARRANTY OR
                REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF CHRISTAPP
                SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER CHRISTAPP NOR
                ANYONE ASSOCIATED WITH CHRISTAPP REPRESENTS OR WARRANTS THAT
                CHRISTAPP SERVICES, ITS CONTENT OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH CHRISTAPP SERVICES WILL BE ACCURATE, RELIABLE,
                ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                THAT ANY CHRISTAPP SERVICE OR THE SERVER INFRASTRUCTURE THAT
                MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS OR THAT ANY CHRISTAPP SERVICE OR ITEMS OBTAINED
                THROUGH ANY SUCH CHRISTAPP SERVICE WILL OTHERWISE MEET YOUR
                NEEDS OR EXPECTATIONS. CHRISTAPP HEREBY DISCLAIMS ALL WARRANTIES
                OF ANY KIND, EITHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
                INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
                NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE
                FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW. LIMITATION ON
                LIABILITY IN NO EVENT WILL THE COLLECTIVE LIABILITY OF
                CHRISTAPP, ITS AFFILIATES AND ITS LICENSORS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, OFFICERS AND DIRECTORS FOR DAMAGES OF ANY
                KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
                WITH YOUR USE, OR INABILITY TO USE, CHRISTAPP SERVICES, WHICH
                INCLUDES WITHOUT LIMITATION, THE WEBSITE, MOBILE APPS AND (A)
                ANY WEBSITE OR SERVICES LINKED TO THERETO, (B) ANY CONTENT ON
                CHRISTAPP SERVICES OR ON SUCH OTHER WEBSITES OR ANY OTHER
                SERVICE LINKED THERETO, (C) ITEMS OBTAINED THROUGH SUCH
                CHRISTAPP SERVICES AND (D) ANY SERVICE OR FUNCTIONALITY RELATED
                TO CHRISTAPP SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
                LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
                DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA
                AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE EXCEED $100.00 (US
                DOLLARS). THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH
                CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. WAIVER OF
                INJUNCTIVE OR OTHER EQUITABLE RELIEF TO THE FULLEST EXTENT NOT
                PROHIBITED BY APPLICABLE LAW, IF YOU CLAIM THAT YOU HAVE
                INCURRED ANY LOSS, DAMAGES, OR INJURIES IN CONNECTION WITH YOUR
                USE OF CHRISTAPP SERVICES, THEN THE LOSSES, DAMAGES, AND
                INJURIES WILL NOT BE DEEMED IRREPARABLE OR SUFFICIENT TO ENTITLE
                YOU TO AN INJUNCTION OR TO OTHER EQUITABLE RELIEF OF ANY KIND.
                IN CONNECTION WITH YOUR CLAIM, YOU AGREE THAT YOU WILL NOT SEEK,
                AND THAT YOU WILL NOT BE PERMITTED TO OBTAIN, ANY COURT OR OTHER
                ACTION THAT MAY INTERFERE WITH OR PREVENT THE DEVELOPMENT OR
                EXPLOITATION OF ANY WEBSITE, APPLICATION, CONTENT,
                USER-GENERATED CONTENT, PRODUCT, SERVICE, OR INTELLECTUAL
                PROPERTY OWNED, LICENSED, USED OR CONTROLLED BY CHRISTAPP
                (INCLUDING YOUR USER CONTRIBUTION) OR A LICENSOR OF CHRISTAPP.
                Indemnification You agree to defend, indemnify and hold harmless
                Christapp, its affiliates and licensors and their respective
                officers, directors, employees, contractors, agents, licensors
                and suppliers from and against any claims, liabilities, damages,
                judgements, awards, losses, costs, expenses and fees (including
                reasonable attorney’s fees) resulting from your violation of
                these Terms of Use or use of the Website, including, without
                limitation, any use of the content, services and products
                related to Christapp Services other than as expressly authorized
                in the Terms of Use or your use of any information obtained from
                any of the Christapp Services. Governing Law and Jurisdiction
                Subject to the arbitration provision below, any legal suit,
                action or proceeding arising out of, or related to, these Terms
                of Use or Christapp Services shall be instituted exclusively in
                the federal courts of the United States or the courts of the
                State of California located in the Redwood City and the County
                of San Mateo, California. You hereby agree to the exclusive
                jurisdiction and venue of such courts and irrevocably waive any
                argument based on inconvenient forum. Arbitration of Disputes
                Certain portions of this section are deemed to be a “written
                agreement to arbitrate” pursuant to the Federal Arbitration Act.
                You and Christapp agree that the parties intend that this
                section satisfies the “writing” requirement of the Federal
                Arbitration Act. This section can only be amended by mutual
                agreement. You and Christapp agree that we will resolve any
                disputes between us through binding and final arbitration
                instead of through court proceedings. You and Christapp hereby
                waive any right to a jury trial of any Claim (as defined
                herein). All controversies, claims, counterclaims, or other
                disputes arising between you and Christapp relating to these
                Terms of Use and Privacy Policy (each a "Claim") shall be
                submitted for binding arbitration in accordance with the Rules
                of the American Arbitration Association ("AAA Rules") in the
                County of San Mateo, Redwood City, California. You agree that
                all Claims will be governed by the laws of the State of
                California without regard to California’s choice of law
                principles. The arbitration will be heard and determined by a
                single arbitrator. The arbitrator's decision in any such
                arbitration will be final and binding upon the parties and may
                be enforced in any court of competent jurisdiction. The parties
                agree that the arbitration will be kept confidential and that
                the existence of the proceeding and any element of it
                (including, without limitation, any pleadings, briefs or other
                documents submitted or exchanged and any testimony or other oral
                submissions and awards) will not be disclosed beyond the
                arbitration proceedings, except as may lawfully be required in
                judicial proceedings relating to the arbitration or by
                applicable disclosure rules and regulations of securities
                regulatory authorities or other governmental agencies. This
                arbitration agreement does not preclude you or Christapp from
                seeking action by federal, state, or local government agencies.
                You and Christapp also have the right to bring qualifying claims
                in small claims court. Any and all claims, judgments, and awards
                will be limited to actual third-party, out-of-pocket costs
                incurred (if any), but in no event will attorneys' fees be
                awarded or recoverable. If any provision of this section is
                found to be invalid or unenforceable, then that specific
                provision shall be of no force and effect and shall be severed,
                but the remainder of this section shall continue in full force
                and effect. No waiver of any provision of this section of these
                Terms of Use will be effective or enforceable unless recorded in
                a writing signed by the party waiving such a right or
                requirement. Such a waiver shall not waive or affect any other
                portion of these Terms of Use. This Section of these Terms of
                Use will survive the termination of your relationship with
                Christapp. THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE
                RIGHT TO MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE
                RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES,
                AND THE RIGHT TO CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER
                RIGHTS THAT YOU OR CHRISTAPP WOULD HAVE IN COURT ALSO MAY NOT BE
                AVAILABLE IN ARBITRATION. Notices for California Residents Under
                California Civil Code Section 1789.3, California users are
                entitled to the following consumer rights notice: If you have a
                question or complaint regarding the Christapp Services, please
                send an email to legal@Christapp.com. You may also contact us by
                writing to 2479 E. Bayshore Road Suite 115, Palo Alto,
                California 94303, Attention: Terms of Use. California residents
                may reach the Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs by mail at 1625 North Market Blvd., Sacramento, CA
                95834, or by telephone at (916) 445-1254 or (800) 952-5210. Any
                California residents under the age of eighteen (18) who have
                registered to use the Christapp Services, and who have posted
                content or information on Christapp Services, can request that
                such information be removed from Christapp Services by
                contacting us at the e-mail or address set forth in the “Contact
                Us” section below making such a request, stating that they
                personally posted such content or information and detailing
                where the content or information is posted. We will make
                reasonable good faith efforts to remove the post from
                prospective public view or anonymize it so the minor cannot be
                individually identified. This removal process cannot ensure
                complete or comprehensive removal. For instance, third-parties
                may have republished the post and archived copies of it may be
                stored by search engines and others that we do not control.
                Limitation on Time to File Claims ANY CAUSE OF ACTION OR CLAIM
                YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR
                PRIVACY POLICY MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
                CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
                CLAIM IS PERMANENTLY BARRED. Waiver and Severability No waiver
                of the Terms of Use by the Company shall be deemed a further or
                continuing waiver of such term or condition or any other term or
                condition, and any failure of the Company to assert a right or
                provision under these Terms of Use shall not constitute a waiver
                of such right or provision. If any provision of these Terms of
                Use is held by a court of competent jurisdiction to be invalid,
                illegal or unenforceable for any reason, such provision shall be
                eliminated or limited to the minimum extent such that the
                remaining provisions of the Terms of Use will continue in full
                force and effect. Entire Agreement The Terms of Use and our
                Privacy Policy constitute the sole and entire agreement between
                you and Christapp, Inc. with respect to Christapp Services and
                supersede all prior and contemporaneous understandings,
                agreements, representations and warranties, both written and
                oral, with respect to Christapp Services.
              </p>
            </div>
        </footer>
      </StyledComponents>
    
  );
};

export default Terms;
const StyledComponents = styled.div`
   background: #636363;
   padding: 40px;
  .bile-container-terms{
    margin: 20px 0;
    background-color: black;
  }
  footer {
    padding: 150px;
    background-color: black;
    color: #fff;
    margin-top: 100px;
  }
  .tems-parar{
    font-family: Arial;
    font-size: 14px;
    color: rgb(248, 248, 248);
    line-height: 26px;
  }
  .container {
    background-color: #1e3e1e;
    color: white;
    text-align: center;
    padding: 40px 20px;
  }

  .feature-grid {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
  }

  .my-story{
    width: 100%;
    font-family: open sans, sans-serif;
    font-size: 38px;
    line-height: 1em;
    margin-bottom: 30px;
    text-align: start;
  }

  .feature-card {
    width: 30%;
    text-align: center;
  }

  .icon {
    width: 80px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .footer-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #45a049;
    }
  }

  .copyright {
    margin: 0;
  }

  .social-icons {
    display: flex;
    justify-content: center;
  }

  .social-icon {
    width: 30px;
    margin: 0 10px;
  }
`;
