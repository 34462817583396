import React from 'react';
import styled from 'styled-components';
import { icon1, icon2, icon3 } from '../utils/images';


function FeatureSection() {
  return (
    <Container>
      <div className="feature-grid">
        <div className="feature-card">
          <img src={icon1} alt="Icon 1" className="icon" />
          <h3 className="title">TWI BIBLE VERSIONS</h3>
          <p className="description">
            Choose from more than 1000 Bible versions in over 50 languages on your computer, phone, or tablet -- with many available as audio Bibles.
          </p>
        </div>
        <div className="feature-card">
          <img src={icon2} alt="Icon 2" className="icon" />
          <h3 className="title">BIBLE STUDIES</h3>
          <p className="description">
            Highlight or Bookmark your favorite verses, make Verse Images that you can share, and attach public or private Notes to Bible passages.
          </p>
        </div>
        <div className="feature-card">
          <img src={icon3} alt="Icon 3" className="icon" />
          <h3 className="title">Service Name</h3>
          <p className="description">
            Twi Bible App is completely free, and available on iOS and Android devices. Download the Free Bible App NOW.
          </p>
        </div>
      </div>
    </Container>
  );
}

export default FeatureSection;

const Container = styled.div`
  background-color: #1e3e1e;
  color: white;
  text-align: center;
  padding: 40px 20px;

  .feature-grid {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
  }

  .feature-card {
    width:25%;
    text-align: center;
  }

  .icon {
    width: 110px;
    height: 140px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .footer {
    background-color: #2e2e2e;
    padding: 20px 0;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-links {
    display: flex;
  }

  .footer-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .copyright {
    margin: 0;
  }
`;
